.select {
  display: inline-flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  border-radius: 0.25rem;
  background-color: hsl(204 20% 100%);
  padding-left: 1rem;
  padding-right: 1rem;
  color: hsl(204 10% 10%);
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
  color-scheme: light;
}

.select:hover {
  background-color: #ede9fe;
}

@media (min-width: 640px) {
  .select {
    height: 2.25rem;
    font-size: 15px;
  }
}

.select-icon {
  translate: 4px 0;
}

.popover {
  z-index: 50;
  max-height: min(var(--radix-select-content-available-height), 336px);
  border-radius: 0.5rem;
  background-color: hsl(204 20% 100%);
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.25),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  color-scheme: light;
}

.combobox-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.25rem;
  padding-bottom: 0px;
}

.combobox {
  height: 2.5rem;
  appearance: none;
  border-radius: 0.25rem;
  background-color: hsl(204 10% 10% / 0.05);
  padding-right: 0.5rem;
  padding-left: 1.75rem;
  color: hsl(204 10% 10%);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.combobox::placeholder {
  color: hsl(204 10% 10% / 0.6);
}

@media (min-width: 640px) {
  .combobox {
    height: 2.25rem;
    font-size: 15px;
  }
}

.combobox-icon {
  pointer-events: none;
  position: absolute;
  left: 0.625rem;
  color: hsl(204 10% 10% / 0.6);
}

.listbox {
  overflow-y: auto;
  padding: 0.25rem;
}

.item {
  position: relative;
  display: flex;
  height: 2.5rem;
  cursor: default;
  scroll-margin-top: 0.25rem;
  scroll-margin-bottom: 0.25rem;
  align-items: center;
  border-radius: 0.25rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  color: hsl(204 10% 10%);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.item[data-active-item] {
  background-color: #ddd6fe;
}

@media (min-width: 640px) {
  .item {
    height: 2.25rem;
    font-size: 15px;
  }
}

.item-indicator {
  position: absolute;
  left: 0.375rem;
}
