/* @import url("https://use.typekit.net/vep0jlq.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: lato, sans-serif;
    }
  };



  
.toggle-bg:after {
    content: '';
    @apply absolute top-0.5 left-0.5 bg-green-200 border border-gray-350 rounded-full h-5 w-5 transition shadow-sm;
}
input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-white bg-gray-350 ;
}
input:checked + .toggle-bg {
    @apply bg-green-200 border-green-200;
} */

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --defaultBackground: "#040869";
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer base {
  html {
    font-family: "Lato", sans-serif;
    font-size: 12.8px;
  }
}

.toggle-bg:after {
  @apply absolute top-0.5 left-0.5 h-5 w-5 rounded-full border border-red-300 bg-red-300 shadow-sm transition;
  content: "";
}
input:checked + .toggle-bg:after {
  transform: translateX(100%);
  @apply border-gray-30 bg-gray-30;
}
input:checked + .toggle-bg {
  @apply border-green-200 bg-green-200;
}

input:checked + .checkbox {
  /* background-color: red; */
  background: url("../images/icons/check-box.svg") center;
  @apply bg-gray-70;
}

.inapp li:has(a[aria-current="page"]) {
  @apply pb-[8px]  text-gray-30;
}
.onboardingnav li,
.public-header li {
  @apply border-transparent;
}
.onboardingnav > ul,
.public-header > ul {
  @apply pb-0;
}
.onboardingnav > ul {
  @apply border-b border-gray-20;
}

button[data-state="open"] svg:last-child:not(:only-child) {
  @apply rotate-180 transition-all;
}
button[data-state="closed"] svg:last-child:not(:only-child) {
  @apply transition-all;
}

.chart-select button {
  @apply px-7;
}
.chart-select:first-child button {
  @apply pt-8 pb-2;
}
.chart-select:last-child button {
  @apply pb-7 pt-2;
}
.chart-select:not(:first-child, :last-child) button {
  @apply py-2;
}

/* styles.css */
.HoverCardContent {
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}
.HoverCardContent[data-side="top"] {
  animation-name: slideUp;
}
.HoverCardContent[data-side="bottom"] {
  animation-name: slideDown;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.progressbar {
  height: 0.25rem;
  width: 100%;
  background-color: transparent;
}

@keyframes poolCard {
  100% {
    @apply w-0 bg-red-40;
  }
  95% {
    @apply w-[5%] bg-red-40;
  }
  90% {
    @apply w-1/12 bg-red-40;
  }
  85% {
    @apply w-1/6 bg-red-40;
  }
  80% {
    @apply w-1/5 bg-gold-20;
  }
  60% {
    @apply w-2/5 bg-gold-20;
  }
  40% {
    @apply w-3/5 bg-yellow-10;
  }
  20% {
    @apply w-4/5 bg-green-400;
  }
  0% {
    @apply w-full bg-green-400;
  }
}
@keyframes uploadimage {
  100% {
    @apply border-red-40;
  }
  50% {
    @apply border-yellow-10;
  }
  0% {
    @apply border-green-400;
  }
}

.ScrollAreaRoot {
  width: 100%;
  height: 100%;
}

.ScrollAreaViewport {
  width: 100%;
  height: 100%;
}

.ScrollAreaScrollbar {
  width: 4px;
  padding: 5px 2px;
}

.ScrollAreaThumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}
.__floater__open {
  padding: 0 !important;
}

* {
  /* outline: 1px solid red */
}
